import   React                 ,  
       { useState              ,
	     useEffect             }  from 'react';

import { useNavigate           }  from 'react-router-dom';

import { Dispatch              }  from 'redux';

import { useDispatch           }  from 'react-redux';

import   classNames               from 'classnames';

import { differenceInYears ,
         parse             , 
         isValid           } from 'date-fns';
		 
import   styles                   from './registration_form.module.scss';

import { Field                 }  from '../../../widget/field/field';
import { Select                }  from '../../../widget/select/select';
import { DateSelector          }  from '../../../widget/date_selector/date_selector';
import { WidgetFrame           }  from '../../../widget/widget_frame/widget_frame';
import { Window                }  from '../../../widget/window/window';
import { WidgetFrameLabel      }  from '../../../widget/widget_frame/widget_frame_label';
import { Toggle                }  from '../../../widget/toggle/toggle';
import { TextArea              }  from '../../../widget/textarea/textarea';
import { AnimatedWaitingIcon   }  from '../../../widget/animated_waiting_icon/animated_waiting_icon';

import { Validation            ,
         checkEmail2           ,
         checkFirstname        ,
         checkLastname         ,
         checkBirthday         ,
         checkAddress          ,
         checkAddress2         ,
         checkZipCode          ,
         checkCity             ,
         checkCountry          ,
         checkPhoneNumber2     }  from '../../../../datas/userManagment/validators';	
		 
import { useTypedSelector      }  from '../../../../datas/useTypeSelector';

import { initAuthentication    }  from '../../../../datas/authenticationManagment/actionCreators';
import { initUser              }  from '../../../../datas/userManagment/actionCreators';

import { postUrbanTourRegistration }   from '../../../../network/api_urban_tour';

import { INTERNAL_LINKS        }  from '../../../../network/urls';

import { postEvent             }  from '../../../../network/api_events_stats';


export interface RegistrationFormProps {
  destination: string | null;
  dates:       string[]
}

export const RegistrationForm = ({ destination ,
                                   dates       }: RegistrationFormProps) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment           );

  const [ email                 , setEmail              ] = useState ( user.email                                    );
  const [ firstname             , setFirstname          ] = useState ( user.firstname                                );
  const [ lastname              , setLastname           ] = useState ( user.lastname                                 );
  const [ birthday              , setBirthday           ] = useState ( user.birthday                                 );
  const [ address               , setAddress            ] = useState ( user.address                                  );
  const [ address2              , setAddress2           ] = useState ( user.address2                                 );
  const [ zipCode               , setZipCode            ] = useState ( user.zipCode                                  );
  const [ city                  , setCity               ] = useState ( user.city                                     );
  const [ country               , setCountry            ] = useState ( user.country === "" ? "France" : user.country );
  const [ phoneNumber           , setPhoneNumber        ] = useState ( user.phoneNumber                              );
  
  const [ messageEmail          , setMessageEmail       ] = useState ('');
  const [ messageFirstname      , setMessageFirstname   ] = useState ('');
  const [ messageLastname       , setMessageLastname    ] = useState ('');
  const [ messageBirthday       , setMessageBirthday    ] = useState ('');
  const [ messageAddress        , setMessageAddress     ] = useState ('');
  const [ messageAddress2       , setMessageAddress2    ] = useState ('');
  const [ messageZipCode        , setMessageZipCode     ] = useState ('');
  const [ messageCity           , setMessageCity        ] = useState ('');
  const [ messageCountry        , setMessageCountry     ] = useState ('');
  const [ messagePhoneNumber    , setMessagePhoneNumber ] = useState ('');
 
 /////
 
  const [ sessionDates               , setSessionDates               ] = useState (dates[0]);
  const [ identityDocument           , setIdentityDocument           ] = useState ('Carte d\'identité');
  const [ identityDocumentValidity   , setIdentityDocumentValidity   ] = useState ('01/01/2025');
  const [ nationality                , setNationality                ] = useState ('Français');
  const [ accompanyingPersonPresence , setAccompanyingPersonPresence ] = useState (false);
  const [ airTransport               , setAirTransport               ] = useState (false);
  const [ departureAirport           , setDepartureAirport           ] = useState ('');
  const [ baggageToBeCheckedIn       , setBaggageToBeCheckedIn       ] = useState (false);
  const [ largeCarryOnBaggage        , setLargeCarryOnBaggage        ] = useState (false);
  const [ airportTransfer            , setAirportTransfer            ] = useState (false);
  const [ carRental                  , setCarRental                  ] = useState (false);
  const [ contactRequest             , setContactRequest             ] = useState (false);
  const [ additionalInformation      , setAdditionalInformation      ] = useState ('');

  const [ messageSessionDates               , setMessageSessionDates               ] = useState ('');
  const [ messageIdentityDocument           , setMessageIdentityDocument           ] = useState ('');
  const [ messageIdentityDocumentValidity   , setMessageIdentityDocumentValidity   ] = useState ('');
  const [ messageNationality                , setMessageNationality                ] = useState ('');
  const [ messageAccompanyingPersonPresence , setMessageAccompanyingPersonPresence ] = useState ('');
  const [ messageAirTransport               , setMessageAirTransport               ] = useState ('');
  const [ messageDepartureAirport           , setMessageDepartureAirport           ] = useState ('');
  const [ messageBaggageToBeCheckedIn       , setMessageBaggageToBeCheckedIn       ] = useState ('');
  const [ messageLargeCarryOnbaggage        , setMessageLargeCarryOnbaggage        ] = useState ('');
  const [ messageAirportTransfer            , setMessageAirportTransfer            ] = useState ('');
  const [ messageCarRental                  , setMessageCarRental                  ] = useState ('');
  const [ messageContactRequest             , setMessageContactRequest             ] = useState ('');
  const [ messageAdditionalInformation      , setMessageAdditionalInformation      ] = useState ('');
 
 /////
 
  const [ showWindow            , setShowWindow         ] = useState ( false );
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  const [ showWindow2           , setShowWindow2        ] = useState ( false );
  const [ windowMessage2        , setWindowMessage2     ] = useState ( <></> );
  const [ expiredSession        , setExpiredSession     ] = useState ( false );
  const [ showWaitingIcon       , setShowWaitingIcon    ] = useState ( false );

  const [ isSmallScreen         , setIsSmallScreen      ] = useState<boolean>(false);

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

  }, [isSmallScreen]); 


  useEffect(() => {

    postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []); 
  
  
  const init = () => {
  
    setEmail              ( user.email                                    );
    setFirstname          ( user.firstname                                );
    setLastname           ( user.lastname                                 );
    setBirthday           ( user.birthday                                 );
    setAddress            ( user.address                                  );
    setAddress2           ( user.address2                                 );
    setZipCode            ( user.zipCode                                  );
    setCity               ( user.city                                     );
    setCountry            ( user.country === "" ? "France" : user.country );
    setPhoneNumber        ( user.phoneNumber                              );
  
    setMessageEmail       ('');
    setMessageFirstname   ('');
    setMessageLastname    ('');
    setMessageBirthday    ('');
    setMessageAddress     ('');
    setMessageAddress2    ('');
    setMessageZipCode     ('');
    setMessageCity        ('');
    setMessageCountry     ('');
    setMessagePhoneNumber ('');
 
    setSessionDates               (dates[0]);
    setIdentityDocument           ('Carte d\'identité');
    setIdentityDocumentValidity   ('01/01/2025');
    setNationality                ('Français');
    setAccompanyingPersonPresence (false);
    setAirTransport               (false);
    setDepartureAirport           ('');
    setBaggageToBeCheckedIn       (false);
    setLargeCarryOnBaggage        (false);
    setAirportTransfer            (false);
    setCarRental                  (false);
    setContactRequest             (false);
    setAdditionalInformation      ('');

    setMessageSessionDates               ('');
    setMessageIdentityDocument           ('');
    setMessageIdentityDocumentValidity   ('');
    setMessageNationality                ('');
    setMessageAccompanyingPersonPresence ('');
    setMessageAirTransport               ('');
    setMessageDepartureAirport           ('');
    setMessageBaggageToBeCheckedIn       ('');
    setMessageLargeCarryOnbaggage        ('');
    setMessageAirportTransfer            ('');
    setMessageCarRental                  ('');
    setMessageContactRequest             ('');
    setMessageAdditionalInformation      ('');
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setEmail    ( e.target.value ) 
    
	const retour: Validation = checkEmail2  ( e.target.value ) 
	setMessageEmail ( retour.comment );
  };

  const handleFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFirstname    ( e.target.value ) 
    
	const retour: Validation = checkFirstname  ( e.target.value ) 
	setMessageFirstname ( retour.comment );
  };

  const handleLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setLastname    ( e.target.value ) 
   
	const retour: Validation = checkLastname  ( e.target.value ) 
	setMessageLastname ( retour.comment );
  };

  const handleBirthdayChange = ( newBirthday: string ) => {

    setBirthday    ( newBirthday ) 
    
	const retour: Validation = checkBirthday  ( newBirthday ) 
	setMessageBirthday ( retour.comment );
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress    ( e.target.value ) 
    
	//const retour: Validation = checkAddress  ( e.target.value ) 
	//setMessageAddress ( retour.comment );
  };

  const handleAddress2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress2    ( e.target.value ) 
    
	//const retour: Validation = checkAddress2  ( e.target.value ) 
	//setMessageAddress2 ( retour.comment );
  };
    
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setZipCode    ( e.target.value ) 
    
	//const retour: Validation = checkZipCode  ( e.target.value ) 
	//setMessageZipCode ( retour.comment );
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCity    ( e.target.value ) 
    
	//const retour: Validation = checkCity  ( e.target.value ) 
	//setMessageCity ( retour.comment );
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCountry    ( e.target.value )
    
	//const retour: Validation = checkCountry  ( e.target.value ) 
	//setMessageCountry ( retour.comment );
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPhoneNumber    ( e.target.value ) 
    
	const retour: Validation = checkPhoneNumber2  ( e.target.value , user.email )
	setMessagePhoneNumber ( retour.comment );
  };

  const checkSessionDates = ( choosenDates: string ) : Validation => {

      var result: Validation = {
      code:    1 ,
      comment: ""
    };

	if ( choosenDates === "" )
	 {
      result.code    = -1;
	  result.comment = "La session est manquante";
	 }

   return result;
  }

  const handleSessionDatesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSessionDates    ( e.target.value )

	const retour: Validation = checkSessionDates  ( e.target.value )
	setMessageSessionDates ( retour.comment );
  };


  const checkIdentityDocument = ( doc: string ) : Validation => {
  
      var result: Validation = {
      code:    1 ,
      comment: ""  
    };

	if ( doc === "" )
	 {
      result.code    = -1;
	  result.comment = "Le type de pièce d'identité est manquant";
	 }
	 
   return result;	 
  } 

  const checkIdentityDocumentValidity = ( validity: string ) : Validation => {
  
    var result: Validation = {
      code:    1 ,
      comment: ""  
    };

    if ( validity === "" )
          {
           result.code    = -1;
           result.comment = "Il manque la date de validité.";  
          }

     else {
           const parsedDate = parse ( validity, 'dd/MM/yyyy', new Date() );
        
           if ( isValid (parsedDate) === false )
		       {
                result.code    = -2;
                result.comment = "La date saisie n'est pas valide.";  
			   }
          }
		  
	console.log (	 "checkIdentityDocumentValidity" ,  validity, result )
		  
    return result;
  };

  const checkNationality = ( nat: string ) : Validation => {
  
      var result: Validation = {
      code:    1 ,
      comment: ""  
    };

	if ( nat === "" )
	 {
      result.code    = -1;
	  result.comment = "La nationalité est manquante";
	 }
	 
   return result;	 
  } 

  const handleIdentityDocumentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  
    setIdentityDocument ( e.target.value )
	
	const retour: Validation = checkIdentityDocumentValidity  ( e.target.value )
	setMessageIdentityDocument ( retour.comment );
  };

  const handleIdentityDocumentValidityChange = ( newIdentityDocumentValidity: string ) => {

    setIdentityDocumentValidity ( newIdentityDocumentValidity )
	
	const retour: Validation = checkIdentityDocumentValidity  ( newIdentityDocumentValidity )
	setMessageIdentityDocumentValidity ( retour.comment );
  }
  
  const handleNationalityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  
    setNationality ( e.target.value )
	
	const retour: Validation = checkNationality  ( e.target.value )
	setMessageNationality ( retour.comment );
  };

  const handleAccompanyingPersonPresenceChange = ( val: boolean ) => {

    setAccompanyingPersonPresence ( val )
  }
  
  const handleAirTransportChange = ( val: boolean ) => {

    setAirTransport ( val )
  }
  
  const handleDepartureAirportChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  
    setDepartureAirport ( e.target.value )
  };

  const handleBaggageToBeCheckedInChange = ( val: boolean ) => {

    setBaggageToBeCheckedIn ( val )
  }
  
  const handleLargeCarryOnBaggageChange = ( val: boolean ) => {

    setLargeCarryOnBaggage ( val )
  }

  const handleAirportTransferChange = ( val: boolean ) => {

    setAirportTransfer ( val )
  }
  
  const handleCarRentalChange = ( val: boolean ) => {

    setCarRental ( val )
  }
  
  const handleContactRequestChange = ( val: boolean ) => {

    setContactRequest ( val )
  }
  
  const handleAdditionalInformationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  
    setAdditionalInformation ( e.target.value )
  };

  const handleValidateButtonClick = () => {

    makeRegistration ();   
  }

  const checkAll = async (): Promise<boolean> => {
  
	const retour01 = checkEmail2        ( email )
	const retour02 = checkFirstname     ( firstname )
	const retour03 = checkLastname      ( lastname )
	const retour04 = checkBirthday      ( birthday )
	//const retour05 = checkAddress       ( address )
	//const retour06 = checkAddress2      ( address2 )
	//const retour07 = checkZipCode       ( zipCode )
	//const retour08 = checkCity          ( city )
	//const retour09 = checkCountry       ( country )
	const retour10 = checkPhoneNumber2  ( phoneNumber , user.email )
	const retour11 = checkSessionDates  ( sessionDates )

	setMessageEmail        ( retour01.comment );
	setMessageFirstname    ( retour02.comment );
	setMessageLastname     ( retour03.comment );
	setMessageBirthday     ( retour04.comment );
	//setMessageAddress      ( retour05.comment );
	//setMessageAddress2     ( retour06.comment );
	//setMessageZipCode      ( retour07.comment );
	//setMessageCity         ( retour08.comment );
	//setMessageCountry      ( retour09.comment );
	setMessagePhoneNumber  ( retour10.comment );
	setMessageSessionDates ( retour11.comment );

	const code:number = retour01.code + 
	                    retour02.code + 
	                    retour03.code + 
	                    retour04.code + 
	                    //retour05.code + 
	                    //retour06.code + 
	                    //retour07.code + 
	                    //retour08.code + 
	                    //retour09.code + 
	                    retour10.code +
	                    retour11.code;
						
	let code2:number = 3;
	
	if ( destination !== "Nice" )
	 {
	  const retourA = checkIdentityDocument         ( identityDocument ) 
	  const retourB = checkIdentityDocumentValidity ( identityDocumentValidity ) 
	  const retourC = checkNationality              ( nationality )
	 
	  code2 = retourA.code + 
	          retourB.code + 
	          retourC.code ;
	 }

    return ( code === 6 ) && ( code2 === 3 );

  }

  const handleCloseButtonClick = () => {

	setWindowMessage ( <></>);
    setShowWindow ( false );
  }

  const handleCloseButtonClick2 = () => {

	setWindowMessage2 ( <></>);
    setShowWindow2 ( false );
    navigate ( `${INTERNAL_LINKS.Home}` );
  }
  
  const makeRegistration = async () => {
 
    const check = await checkAll ()
 
    if ( check )
	 {
      const eventMessage: string = "Button:\"Enregistrer\":"   
                                 + "destination="                +   destination !== null ? ( destination as string) : ""  + ','
	                             + "sessionDates="               +   sessionDates                                          + ','
	                             + "email="                      +   email                                                 + ','
	                             + "firstname="                  +   firstname                                             + ','
	                             + "lastname="                   +   lastname                                              + ','
	                             + "birthday="                   +   birthday                                              + ','
	                             + "address="                    +   address                                               + ','
	                             + "address2="                   +   address2                                              + ','
	                             + "zipCode="                    +   zipCode                                               + ','
	                             + "city="                       +   city                                                  + ','
	                             + "country="                    +   country                                               + ','
	                             + "phoneNumber="                +   phoneNumber                                           + ','
	                             + "identityDocument="           +   identityDocument                                      + ','
	                             + "identityDocumentValidity="   +   identityDocumentValidity                              + ','
	                             + "nationality="                +   nationality                                           + ','
	                             + "accompanyingPersonPresence=" + ( accompanyingPersonPresence ? "true":"false" )         + ','
	                             + "airTransport="               + ( airTransport               ? "true":"false" )         + ','
	                             + "departureAirport="           +   departureAirport                                      + ','
	                             + "baggageToBeCheckedIn="       + ( baggageToBeCheckedIn       ? "true":"false" )         + ','
	                             + "largeCarryOnBaggage="        + ( largeCarryOnBaggage        ? "true":"false" )         + ','
	                             + "airportTransfer="            + ( airportTransfer            ? "true":"false" )         + ','
	                             + "carRental="                  + ( carRental                  ? "true":"false" )         + ','
	                             + "contactRequest="             + ( contactRequest             ? "true":"false" )         + ','
	                             + "additionalInformation="      +   additionalInformation

      postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , "" )	 
	 
      setShowWaitingIcon (true);
	 
      const response = await postUrbanTourRegistration ( destination !== null ? ( destination as string) : "" ,
	                                                     sessionDates                      ,
	                                                     email                      ,
	                                                     firstname                  ,
	                                                     lastname                   ,
	                                                     birthday                   ,
	                                                     address                    ,
	                                                     address2                   ,
	                                                     zipCode                    ,
	                                                     city                       ,
	                                                     country                    ,
	                                                     phoneNumber                ,
	                                                     identityDocument           ,
	                                                     identityDocumentValidity   ,
	                                                     nationality                ,
	                                                     accompanyingPersonPresence ,
	                                                     airTransport               ,
	                                                     departureAirport           ,
	                                                     baggageToBeCheckedIn       ,
	                                                     largeCarryOnBaggage        ,
	                                                     airportTransfer            ,
	                                                     carRental                  ,
	                                                     contactRequest             ,
	                                                     additionalInformation      )
									
	  switch ( response.code )
	   {
	    case 1    : setWindowMessage2 ( <>Nous avons bien reçu votre demande, elle sera traitée sous 24h (jours ouvrés)</> );
                    setShowWindow2 ( true );
		
		            break;
		
		default   : setWindowMessage ( <>Votre demande n'a pas pu être envoyée, veuillez recommancer</> );
                    setShowWindow ( true );

	   }
	   
      setShowWaitingIcon (false);	   
     }	 
   }

  const renderSmallSizeComponent = (): JSX.Element => {

    return  <>
	
         <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400 text-center">
            Demande d'informations
         </p>
              
			   <br/>
			  
               <div className={classNames(styles.form)}>

                 <div className={classNames(styles.layout_2_line_1)}>

                   <WidgetFrame name               = "Période souhaitée"
                                error              = {messageSessionDates}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Select choices       = { dates }
                                value         = { sessionDates }
                                onChange      = { handleSessionDatesChange }	/>

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_2)}>

			       <WidgetFrame name               = "Email"
                                error              = {messageEmail}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >

                        <Field  type          = "text"
                                placeholder   = ""
								value         = {email}
                                onChange      = {handleEmailChange} />
				  
				  </WidgetFrame>
				  
                 </div>

                 <div className={classNames(styles.layout_2_line_3)}>

			       <WidgetFrame name               = "Prénom"
                                error              = {messageFirstname}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >

                        <Field  type          = "text"
                                placeholder   = ""
								value         = {firstname}
                                onChange      = {handleFirstnameChange} />
				  
				  </WidgetFrame>
				  
                 </div>

                 <div className={classNames(styles.layout_2_line_4)}>

			       <WidgetFrame name               = "Nom"
                                error              = {messageLastname}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
								value         = {lastname}
                                onChange      = {handleLastnameChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_5)}>

                   <WidgetFrame name               = "Date de naissance"
                                error              = {messageBirthday}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <DateSelector value    = {birthday}
						              onChange = {handleBirthdayChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_6)}>

                   <WidgetFrame name               = "Adresse"
                                error              = {messageAddress}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address}
                                onChange      = {handleAddressChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_7)}>

                   <WidgetFrame name               = "Complément d'adresse"
                                error              = {messageAddress2}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address2}
                                onChange      = {handleAddress2Change} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_8)}>

                   <WidgetFrame name               = "Code postal"
                                error              = {messageZipCode}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {zipCode}
                                onChange      = {handleZipCodeChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_9)}>

                   <WidgetFrame name               = "Ville"
                                error              = {messageCity}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {city}
                                onChange      = {handleCityChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_10)}>

			       <WidgetFrame name               = "Pays"
                                error              = {messageCountry}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {country}
                                onChange      = {handleCountryChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_11)}>

                   <WidgetFrame name               = "Numéro de téléphone"
                                error              = {messagePhoneNumber}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {phoneNumber}
                                onChange      = {handlePhoneNumberChange} />

				  </WidgetFrame>

                 </div>

              {
			    ( destination !== "Nice" ) &&
				(
				 <>
                 <div className={classNames(styles.layout_2_line_12)}>

                   <WidgetFrame name               = "Pièce d'identité"
                                error              = {messageIdentityDocument}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Select choices       = { [
                                                    "Carte d'identité",
                                                    "Passeport"
                                                  ] }
                                value         = {identityDocument}
                                onChange      = {handleIdentityDocumentChange}	/>

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_13)}>

                   <WidgetFrame name               = "Validité de la pièce d'identité"
                                error              = {messageIdentityDocumentValidity}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <DateSelector value         = {identityDocumentValidity}
						              onChange      = {handleIdentityDocumentValidityChange} 
									  beginningYear = {2000} 
									  endingYear    = {2040}  />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_14)}>

                   <WidgetFrame name               = "Nationalité"
                                error              = {messageNationality}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {nationality}
                                onChange      = {handleNationalityChange} />

				  </WidgetFrame>

                 </div>
				 </>
                )
			  }
              {/*
                 <div className={classNames(styles.layout_2_line_15)}>

                    <WidgetFrame error              = {messageAccompanyingPersonPresence}
	    						 classNameGrid      = {classNames(styles.grid_accompanyingPersonPresence)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_accompanyingPersonPresence)}>
					 
					   Présence d'un accompagnant 

					 </WidgetFrameLabel>

                     <Toggle checked        = {accompanyingPersonPresence}
                     	     onChange       = {handleAccompanyingPersonPresenceChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>
                */}
                 <div className={classNames(styles.layout_2_line_16)}>

                    <WidgetFrame error              = {messageAirTransport}
	    						 classNameGrid      = {classNames(styles.grid_airTransport)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_airTransport)}>
					 
					   Transport aérien souhaité

					 </WidgetFrameLabel>

                     <Toggle checked        = {airTransport}
                     	     onChange       = {handleAirTransportChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

               {
                airTransport &&
                (
				 <>
				 
                 <div className={classNames(styles.layout_2_line_17)}>

                   <WidgetFrame name               = "Aéroport de départ"
                                error              = {messageDepartureAirport}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {departureAirport}
                                onChange      = {handleDepartureAirportChange} />

				  </WidgetFrame>

                 </div>
				 
                 <div className={classNames(styles.layout_2_line_18)}>

                    <WidgetFrame error              = {messageBaggageToBeCheckedIn}
	    						 classNameGrid      = {classNames(styles.grid_baggageToBeCheckedIn)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_baggageToBeCheckedIn)}>
					 
					   Bagages à enregistrer en soute 

					 </WidgetFrameLabel>

                     <Toggle checked        = {baggageToBeCheckedIn}
                     	     onChange       = {handleBaggageToBeCheckedInChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames(styles.layout_2_line_19)}>

                    <WidgetFrame error              = {messageLargeCarryOnbaggage}
	    						 classNameGrid      = {classNames(styles.grid_largeCarryOnBaggage)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_largeCarryOnBaggage)}>
					 
					   Gros bagage à main 

					 </WidgetFrameLabel>

                     <Toggle checked        = {largeCarryOnBaggage}
                     	     onChange       = {handleLargeCarryOnBaggageChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames(styles.layout_2_line_20)}>

                    <WidgetFrame error              = {messageAirportTransfer}
	    						 classNameGrid      = {classNames(styles.grid_airportTransfer)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_airportTransfer)}>
					 
					   Transfert aéroport 

					 </WidgetFrameLabel>

                     <Toggle checked        = {airportTransfer}
                     	     onChange       = {handleAirportTransferChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames(styles.layout_2_line_21)}>

                    <WidgetFrame error              = {messageCarRental}
	    						 classNameGrid      = {classNames(styles.grid_carRental)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_carRental)}>
					 
					   Location de voiture 

					 </WidgetFrameLabel>

                     <Toggle checked        = {carRental}
                     	     onChange       = {handleCarRentalChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>
				 
				 </>
				 )
				}

                 <div className={classNames(styles.layout_2_line_22)}>

                   <WidgetFrame name               = "Informations complémentaires"
                                error              = {messageAdditionalInformation}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <TextArea  placeholder   = ""
                                   value         = {additionalInformation}
                                   onChange      = {handleAdditionalInformationChange} />

				  </WidgetFrame>

                 </div>
				 
                 <div className={classNames(styles.layout_2_line_23)}>

                    <WidgetFrame error              = {messageContactRequest}
	    						 classNameGrid      = {classNames(styles.grid_contactRequest)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_contactRequest)}>
					 
					   Je souhaite être contacté(e) pour finaliser ma préinscription

					 </WidgetFrameLabel>

                     <Toggle checked        = {contactRequest}
                     	     onChange       = {handleContactRequestChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames(styles.layout_2_line_24)}>

					
                       <div className={classNames(styles.buttons_block)}>
                        <br/>
						{/*
					    <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleEditButtonClick}>
                           Annuler
                        </button>

                        <br/>
                        <br/>
                        */}
                        <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidateButtonClick}>
                           Enregistrer
                        </button>
					   </div>
                       <br/>
								  

                 </div>

                            
               </div>

	
	        </>
  }

  const renderBigSizeComponent = (): JSX.Element => {

    return  <>
	
         <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400 text-center">
            Demande d'informations
         </p>
              
			   <br/>
			  
               <div className={classNames(styles.form)}>

                 <div className={classNames(styles.layout_1_line_1)}>

                   <WidgetFrame name               = "Période souhaitée"
                                error              = {messageSessionDates}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Select choices       = { dates }
                                value         = { sessionDates }
                                onChange      = { handleSessionDatesChange }	/>

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_2)}>

			       <WidgetFrame name               = "Email"
                                error              = {messageEmail}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >

                        <Field  type          = "text"
                                placeholder   = ""
								value         = {email}
                                onChange      = {handleEmailChange} />
				  
				  </WidgetFrame>
				  
                 </div>

                 <div className={classNames(styles.layout_1_line_3_left)}>

			       <WidgetFrame name          = "Prénom"
                                error         = {messageFirstname}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >

                        <Field  type          = "text"
                                placeholder   = ""
								value         = {firstname}
                                onChange      = {handleFirstnameChange} />
				  
				  </WidgetFrame>
				  
                 </div>

                 <div className={classNames(styles.layout_1_line_3_right)}>

			       <WidgetFrame name          = "Nom"
                                error         = {messageLastname}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >

                        <Field  type          = "text"
                                placeholder   = ""
								value         = {lastname}
                                onChange      = {handleLastnameChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_4)}>

                   <WidgetFrame name          = "Date de naissance"
                                error         = {messageBirthday}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >
								
                        <DateSelector value    = {birthday}
						              onChange = {handleBirthdayChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_5)}>

                   <WidgetFrame name          = "Adresse"
                                error         = {messageAddress}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address}
                                onChange      = {handleAddressChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_6)}>

                   <WidgetFrame name          = "Complément d'adresse"
                                error         = {messageAddress2}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address2}
                                onChange      = {handleAddress2Change} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_7_left)}>

                   <WidgetFrame name          = "Code postal"
                                error         = {messageZipCode}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {zipCode}
                                onChange      = {handleZipCodeChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_7_right)}>

                   <WidgetFrame name          = "Ville"
                                error         = {messageCity}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {city}
                                onChange      = {handleCityChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_8_left)}>

			       <WidgetFrame name          = "Pays"
                                error         = {messageCountry}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {country}
                                onChange      = {handleCountryChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_8_right)}>

                   <WidgetFrame name          = "Numéro de téléphone"
                                error         = {messagePhoneNumber}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {phoneNumber}
                                onChange      = {handlePhoneNumberChange} />

				  </WidgetFrame>

                 </div>
				 
				 
              {
			    ( destination !== "Nice" ) &&
				(
				 <>
                 <div className={classNames(styles.layout_1_line_9)}>

                   <WidgetFrame name               = "Pièce d'identité"
                                error              = {messageIdentityDocument}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Select choices       = { [
                                                    "Carte d'identité",
                                                    "Passeport"
                                                  ] }
                                value         = {identityDocument}
                                onChange      = {handleIdentityDocumentChange}	/>

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_10)}>

                   <WidgetFrame name               = "Validité de la pièce d'identité"
                                error              = {messageIdentityDocumentValidity}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <DateSelector value         = {identityDocumentValidity}
						              onChange      = {handleIdentityDocumentValidityChange} 
									  beginningYear = {2000} 
									  endingYear    = {2040}  />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_11)}>

                   <WidgetFrame name               = "Nationalité"
                                error              = {messageNationality}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {nationality}
                                onChange      = {handleNationalityChange} />

				  </WidgetFrame>

                 </div>
				 </>
                )
   			  }
				{/*
                 <div className={classNames(styles.layout_1_line_12)}>

                    <WidgetFrame error              = {messageAccompanyingPersonPresence}
	    						 classNameGrid      = {classNames(styles.grid_accompanyingPersonPresence)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_accompanyingPersonPresence)}>
					 
					   Présence d'un accompagnant 

					 </WidgetFrameLabel>

                     <Toggle checked        = {accompanyingPersonPresence}
                     	     onChange       = {handleAccompanyingPersonPresenceChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>
*/}
                 <div className={classNames( ( destination !== "Nice" ) ? styles.layout_1_line_12 : styles.layout_1_line_9)}>

                    <WidgetFrame error              = {messageAirTransport}
	    						 classNameGrid      = {classNames(styles.grid_airTransport)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_airTransport)}>
					 
					   Transport aérien 

					 </WidgetFrameLabel>

                     <Toggle checked        = {airTransport}
                     	     onChange       = {handleAirTransportChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>
				 
               {
                airTransport &&
                (
				 <>
				 
                 <div className={classNames( ( destination !== "Nice" ) ? styles.layout_1_line_13 : styles.layout_1_line_10 )}>

                   <WidgetFrame name               = "Aéroport de départ"
                                error              = {messageDepartureAirport}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {departureAirport}
                                onChange      = {handleDepartureAirportChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames( ( destination !== "Nice" ) ? styles.layout_1_line_14 : styles.layout_1_line_11 )}>

                    <WidgetFrame error              = {messageBaggageToBeCheckedIn}
	    						 classNameGrid      = {classNames(styles.grid_baggageToBeCheckedIn)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_baggageToBeCheckedIn)}>
					 
					   Bagages à enregistrer en soute 

					 </WidgetFrameLabel>

                     <Toggle checked        = {baggageToBeCheckedIn}
                     	     onChange       = {handleBaggageToBeCheckedInChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames( ( destination !== "Nice" ) ? styles.layout_1_line_15 : styles.layout_1_line_12 )}>

                    <WidgetFrame error              = {messageLargeCarryOnbaggage}
	    						 classNameGrid      = {classNames(styles.grid_largeCarryOnBaggage)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_largeCarryOnBaggage)}>
					 
					   Gros bagage à main 

					 </WidgetFrameLabel>

                     <Toggle checked        = {largeCarryOnBaggage}
                     	     onChange       = {handleLargeCarryOnBaggageChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames( ( destination !== "Nice" ) ? styles.layout_1_line_16 : styles.layout_1_line_13 )}>

                    <WidgetFrame error              = {messageAirportTransfer}
	    						 classNameGrid      = {classNames(styles.grid_airportTransfer)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_airportTransfer)}>
					 
					   Transfert aéroport 

					 </WidgetFrameLabel>

                     <Toggle checked        = {airportTransfer}
                     	     onChange       = {handleAirportTransferChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames( ( destination !== "Nice" ) ? styles.layout_1_line_17 : styles.layout_1_line_14 )}>

                    <WidgetFrame error              = {messageCarRental}
	    						 classNameGrid      = {classNames(styles.grid_carRental)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_carRental)}>
					 
					   Location de voiture 

					 </WidgetFrameLabel>

                     <Toggle checked        = {carRental}
                     	     onChange       = {handleCarRentalChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>
				 
				 </>
				)
			   }
				 {/*
                 <div className={classNames(styles.layout_1_line_19)}>

                   <WidgetFrame name               = "Informations complémentaires"
                                error              = {messageAdditionalInformation}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                        <TextArea  placeholder   = ""
                                   value         = {additionalInformation}
                                   onChange      = {handleAdditionalInformationChange} />

				  </WidgetFrame>

                 </div>
				 */}
                 <div className={classNames(airTransport ? ( ( destination !== "Nice" ) ? styles.layout_1_line_18 : styles.layout_1_line_15 ) : ( ( destination !== "Nice" ) ? styles.layout_1_line_13 : styles.layout_1_line_10 ) )}>

                    <WidgetFrame error              = {messageContactRequest}
	    						 classNameGrid      = {classNames(styles.grid_contactRequest)} 
								 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_contactRequest)}>
					 
					   Je souhaite être contacté(e) en vue de ma préinscription

					 </WidgetFrameLabel>

                     <Toggle checked        = {contactRequest}
                     	     onChange       = {handleContactRequestChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                  <div className={classNames(airTransport ? ( ( destination !== "Nice" ) ? styles.layout_1_line_19 : styles.layout_1_line_16 ) : ( ( destination !== "Nice" ) ? styles.layout_1_line_14 : styles.layout_1_line_11 ) )}>

                  <div className={classNames(styles.buttons)}>
					

						
                        <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidateButtonClick}>
                           Brochure & Programme détaillé
                        </button>
								  

                  </div>

                 </div>

                            
               </div>

	
	        </>
  }

  return (
            
           <div className={classNames(styles.root)}>

	           { ( showWaitingIcon ) && ( <AnimatedWaitingIcon/>) }
               
			   {
			    showWindow  &&
                (
				  < Window message              = {windowMessage}
				           onClickOnCloseButton = {handleCloseButtonClick} />
				)				
			   }

			   {
			    showWindow2  &&
                (
				  < Window message              = {windowMessage2}
				           onClickOnCloseButton = {handleCloseButtonClick2} />
				)				
			   }

			 { 
			  ( isSmallScreen ? renderSmallSizeComponent() : renderBigSizeComponent () ) 
			 }


           </div>
           
         );
};


export default RegistrationForm;
