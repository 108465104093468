import   React              ,
       { useEffect          ,
         useState           }  from 'react';

import { Link               ,
         useLocation        ,
		 useNavigate        }  from 'react-router-dom';

import { Dispatch           }  from 'redux';
import { useDispatch        }  from 'react-redux';

import   classNames            from 'classnames';

import   styles                from './part_02.module.scss';

import { BackgroundGradient }  from "../../../../../ui/background-gradient";

import { Window             }  from '../../../../../widget/window/window';
import { WindowWithField    }  from '../../../../../widget/window/window_with_field';
import { WindowWithForm     }  from '../../../../../widget/window/window_with_form';

import { postInformationRequest }  from '../../../../../../network/api_urban_tour';




import { useTypedSelector   }  from '../../../../../../datas/useTypeSelector';

import { initCart           }  from '../../../../../../datas/cartManagment/actionCreators';
import { initAuthentication }  from '../../../../../../datas/authenticationManagment/actionCreators';
import { initUser           }  from '../../../../../../datas/userManagment/actionCreators';

import { postEvent          }  from '../../../../../../network/api_events_stats';

import { INTERNAL_LINKS     ,
         WWW_URLS           }  from '../../../../../../network/urls';
import { isTokenValid       }  from '../../../../../../network/utils'; 


export interface Part02Props {
  showAlertWindow: () => void;
}

export const Part02 = ({ showAlertWindow }: Part02Props) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate();

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment           );

  const [ alertInformationRequestWindowVisible , setAlertInformationRequestWindowVisible ] = useState ( false );
  const [ alertWindowDeprecatedTokenVisible    , setAlertWindowDeprecatedTokenVisible    ] = useState ( false );

  const currentUrbanTour: string = "Lovelight Urban Tour spécial Nice"

   const renderText4 = (): JSX.Element => {

      return (
               <div className={classNames(styles.block_text)}>
                  <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
                     Retrouvez l'équipe de Lovelight et enrichissez vos compétences photographiques dans la capitale de la Côte d'Azur
                  </p>
                  <br/>
                  <br/>
		          <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
                     Un séjour unique à Nice vous attend, une ville où la Méditerranée embrasse l’élégance de la Côte d’Azur. C’est une occasion de capturer 
					 des paysages qui deviendront vos propres cartes postales tout en perfectionnant vos bases photographiques. Cette Masterclass en petit 
					 groupe (8 à 10 personnes) se déroulera sur les sites emblématiques tels que la Promenade des Anglais, les ruelles du Vieux Nice jusqu’à 
					 des lieux plus contemporains ou insolites, sans oublier le restaurant “Les Cocottes Françaises” où vous pourrez découvrir la photo culinaire 
					 avant de déguster à la carte de ce restaurant les traditionnelles recettes en cocotte avec des mets d’exception réalisés avec des produits de 
					 qualité. Vous disposerez également de moments libres qui vous permettront de flânez au gré de vos envies et à votre rythme.<br/>
                     <br/>
                     Cette Masterclass spécialement organisée pour les amateurs ou experts passionnés de photographie vous permettra d’échanger avec d’autres 
					 membres de la communauté Lovelight. Ce séjour offre l’opportunité de saisir la beauté lumineuse de Nice tout en perfectionnant sa technique 
					 photographique et son regard artistique. Accompagné du photographe niçois Ilan Dehé, membre du jury du Lovelight Challenge, et de l’équipe 
					 Lovelight, vous apprendrez de nouvelles techniques à travers des ateliers et des photowalks, comme la prise de vue en mode manuel, 
					 la photo culinaire, la photo d’architecture et de nuit ainsi que la photographie de rue et de portrait au flash et en lumière naturelle.<br/>
                     <br/>
                     Une lecture de portfolio de chaque participant sera menée par Ilan Dehé proposant ainsi des conseils et revenant sur les erreurs à éviter.
		          </p>


                  <br/>

               </div>
             )
   };

   const renderThumbnail4 = (): JSX.Element => {

      return <>
    	  <div className={classNames(styles.contain)}>
		  
		  
      <div className={classNames(styles.subcontain)}>
        <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
           Les prochaines sessions
        </p>
        <p className="text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
           Du 21 au 23 février 2025
           <br/>
           Du 28 au 30 mars 2025
        </p>

          <div className={classNames(styles.btn)}>

      
	       <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
		           onClick   = {handleSubscriptionOnUrbanTour}>
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">
               Brochures & Inscriptions
             </span>
           </button>
     {/*		   
		   <button className = "px-6 py-2 rounded-full bg-[#333333] font-bold text-sm text-[#666666] tracking-widest uppercase transform border border-black  cursor-default"
                   onClick   = {handleSubscriptionOnUrbanTour}>
              Je m'inscris
           </button>
		   */}
		   
		   
          </div>
      </div>
		  
		  
		  
      <BackgroundGradient className="rounded-[22px] p-4 sm:p-10 bg-black dark:bg-zinc-900">
	    <div className={classNames(styles.image)}>
        <img
          src={`${WWW_URLS.UrbanTourThumbnail}Nice/Negresco.jpg`}
          alt="Urban Tour Nice Negresco"
          height="400"
          width="400"
          className="object-contain"
        />
        </div>
        <p className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200">
          Séances de photographie guidées pas à pas
        </p>
         <p className="text-sm text-justify text-neutral-600 text-white dark:text-neutral-400">
          Découvrez la photo de paysage, la photo d’architecture, la photo culinaire, la photo de rue et de nuit. Explorez les lieux emblématiques de Nice, 
		  tels que la Promenade des Anglais, le Vieux-Nice, la Place Masséna accompagné d’un photographe professionnel et de l’équipe Lovelight.
        </p>
        <p className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200">
          Ateliers pratiques quotidiens
        </p>
         <p className="text-sm text-justify text-neutral-600 text-white dark:text-neutral-400">
          Les ateliers vous permettront d’améliorer vos compétences photographiques techniques et artistiques. Le mode manuel, la streetphotography, 
		  la photo culinaire, la photographie en pose longue, la photographie de portrait et la photographie de nuit seront les principaux thèmes abordés.
        </p>

      </BackgroundGradient>
    </div>


           </>
   }


  
  const handleInformationRequest = () => {

    const eventMessage: string = "UrbanTour-Nice-Part02-Button01:\"Demander la brochure\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertInformationRequestWindowVisible ( true );
  }

  const handleSubscriptionOnUrbanTour = () => {
/*
    let status : number = -1; // Non connecté

    if ( ( authentication.login !== ""   &&
           authentication.token !== "" ) )
     {
      status = 0;

       if ( user.status  === "Standard" )  { status = 1 }
       if ( user.status  === "Premium"  )  { status = 2 }
     }

    if ( status !== -1 )
          {
           const eventMessage: string = "UrbanTour-Nice-Part02-Button02:\"Je m'inscris\""

           postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

           const dest = encodeURIComponent ("Nice");

           navigate ( `${INTERNAL_LINKS.UrbanTour_Registration}?destination=${dest}` );
          }
     else {
           showAlertWindow ()
          }
		  */
		  
           const eventMessage: string = "UrbanTour-Nice-Part02-Button02:\"Je m'inscris\""

           postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

           const dest = encodeURIComponent ("Nice");

           navigate ( `${INTERNAL_LINKS.UrbanTour_Registration}?destination=${dest}` );
  }

  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }
  
  /*
  const closeAlertInformationRequestWindowWithCancelButton = () => {

    setAlertInformationRequestWindowVisible ( false );
  }

  const closeAlertInformationRequestWindowWithValidButton = ( firstname: string ,
                                                              lastname:  string ,
                                                              age:       number ,
                                                              email:     string ,
                                                              city:      string ) => {

    setAlertInformationRequestWindowVisible ( false );

    postInformationRequest ( firstname, lastname, age, email, city, `${currentUrbanTour}` )
  }
*/
  return (

            <div className={classNames(styles.part2)}>

			 			 
			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }

	          {/*
	    alertInformationRequestWindowVisible &&
	    ( < WindowWithForm  message               = { <><span className = {classNames(styles.bold_text)}>Je souhaite recevoir par mail des information concernant l'offre "{currentUrbanTour}".</span><br/></> }
                            messageType           = "normal"
                            onClickOnCancelButton = {closeAlertInformationRequestWindowWithCancelButton}
                            onClickOnValidButton  = {closeAlertInformationRequestWindowWithValidButton}
                            cancelButtonName      = "NON"
                            validButtonName       = "OUI"
							stylesGridInfo        = {classNames(styles.grid_info)} /> )
	  */}

               <div className = { classNames ( styles.grid ) } >

	              <div className = { classNames ( styles.block_4_1 ) } > { renderText4      () } </div>
                  <div className = { classNames ( styles.block_4_2 ) } > { renderThumbnail4 () } </div>

	           </div>

            </div>

          );
};

export default Part02;



