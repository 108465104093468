import   React                       ,
       { useEffect                   ,
	     useState                    }  from 'react';
		
	
import { useNavigate                 }  from 'react-router-dom';
		 
import   classNames                     from 'classnames';


import { Dispatch                    }  from 'redux';
import { useDispatch                 }  from 'react-redux';

import   styles                         from './donation_success.module.scss';

import { TypewriterEffectSmooth      }  from "../../ui/typewriter-effect";

import { GlobalMenu                  }  from '../../menu/global/global';
import { CookiesManager              }  from '../../cookie/manager';
import { Trigger                     }  from '../../widget/trigger/trigger';
import { Footer                      }  from '../../menu/footer/footer';

import { WWW_URLS                    ,
         INTERNAL_LINKS              }  from '../../../network/urls';

import { useTypedSelector            }  from '../../../datas/useTypeSelector';

import { initAuthentication          }  from '../../../datas/authenticationManagment/actionCreators';
		 
import { initCart                    ,
         setCart                     }  from '../../../datas/cartManagment/actionCreators';

import { updateStatus                ,
         updateStatusDeadline        ,
         updateNextStatus            ,
         updateNextStatusRequestDate ,
         initPaymentsSet             ,
         addPayment                  ,
		 initUser                    ,
         setUser                     }  from '../../../datas/userManagment/actionCreators';

import { getCart                     }  from '../../../network/api_cart';
		 
import { getUserInfos                }  from '../../../network/api_user';

import { getPaymentInfos             }  from '../../../network/api_payment';

import { postEvent                   }  from '../../../network/api_events_stats';

export const DonationSuccess = () => {


  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { user           } = useTypedSelector ((state) => state.userManagment );

  const [ version , setVersion ] = useState (0)
  const [isSmallScreen   , setIsSmallScreen  ] = useState(false);

  const switchWidthLimit = 615;

  // Fonction pour ajuster isSmallScreen selon la taille de l'écran
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= switchWidthLimit);
  };


  useEffect(() => {

    window.scroll(0, 0);


    handleResize(); // Appel initial pour vérifier la taille de l'écran
    window.addEventListener('resize', handleResize);
    
	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    reinitCart ()
	
	return () => {
      window.removeEventListener('resize', handleResize);
    };
		

  }, []); 

  useEffect(() => {
	
  }, [version, isSmallScreen]); 

  const handleClick = () => {
  
    updateInformations ();
  }

  const reinitCart = async () => {
    
	if ( authentication.login === "" || authentication.token === "" || user.state === "New" || user.state === "new" )
	      {
    dispatch ( initUser   () )
    dispatch ( initCart   () )
	dispatch ( initAuthentication (true) )
   	      }
	 else {
	       const userCart = await getCart ( authentication.login ,
                                            authentication.token ) 

           dispatch ( setCart ( userCart.cart ) ) 
	      }
    setVersion (version + 1)
  }

  const updateInformations = async () => {
 
    const userInfos = await getUserInfos ( authentication.login ,
                                           authentication.token )
                   
				   
	// On ne teste pas l'âge à cet endroit là car :

    //   - soit c'est pendant le processus d'inscription (login automatique)
    //   - soit depuis le compte du user (l'âge a été testé au moment du choix de l'abonnement	
				   
    if ( userInfos.code === 1 )
          {
           dispatch ( updateStatus                ( userInfos.infos.status               ) )
           dispatch ( updateStatusDeadline        ( userInfos.infos.statusDeadline       ) )
           dispatch ( updateNextStatus            ( userInfos.infos.nextStatus            ) )
           dispatch ( updateNextStatusRequestDate ( userInfos.infos.nextStatusRequestDate ) )

           dispatch ( initPaymentsSet () )

           const paymentInfos = await getPaymentInfos ( authentication.login ,
                                                        authentication.token ) 
                          
	       console.log (paymentInfos)
						  
           for ( let p = 0 ; p < paymentInfos.payments.length ; p++ )
            {
             const payment = paymentInfos.payments[p];
                 
             dispatch ( addPayment ( payment ) ) 
            }
                     
           window.location.href  = `${WWW_URLS.AccountManagment}`
          }
     else {
           dispatch ( initUser   () );
           dispatch ( initAuthentication (true) );

		   
           navigate ( `${INTERNAL_LINKS.Login}` );
		  }
   }

  const logout = () => {



    navigate ( `${INTERNAL_LINKS.Login}` );
  }

  const words = [
    {
      text: "Merci",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "pour",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "votre",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "don !",
      className: "text-green-500 dark:text-green-500",
    },
  ];
  
  return (
 
    <>

      <GlobalMenu version={version}/>
      <CookiesManager />

  	  <div className={classNames(styles.root)}>

    	<div className={classNames(styles.contain)}>

   	     <div className={classNames(styles.message)}>
		 
            {
		      (isSmallScreen)
		      ?
		      (
                <p className="text-green-500 dark:text-green-500 text-[18px] font-bold">Merci pour votre don !</p>
		      )
		      :
		      (
                <TypewriterEffectSmooth words={words} />
			  )
			}
			
         </div>

     	 <div className={classNames(styles.message2)}>
		   &#x2764;&nbsp;&#x2764;&nbsp;&#x2764;
		   <br/>
		   <br/>
  	     </div>

     	 <div className={classNames(styles.message3)}>
		   {
		     (
			   authentication.login === "" || authentication.token === "" || user.state === "New" || user.state === "new"
			 )
			 ?
			 (
               <Trigger onClick         = {logout}
		                name            = "Page de connexion"
                        classNameNormal = {classNames(styles.button)} />
			 )
			 :
			 (
               <Trigger onClick         = {handleClick}
		                name            = "Retour au compte utilisateur"
                        classNameNormal = {classNames(styles.button)} />
			 )
		   }
		   
  	     </div>
		 
        </div>

	  </div>

    <Footer />
 
    </>
	
  );
};

export default DonationSuccess;

