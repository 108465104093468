import   React            ,
       { useEffect        ,
         useState         }  from 'react';

import { Link             ,
         useNavigate      }  from 'react-router-dom';

import   classNames          from 'classnames';

import   styles              from './part_01.module.scss';

import { HoverEffect      ,
         Item             }  from "../../../../ui/card-hover-effect";

import { useTypedSelector }  from '../../../../../datas/useTypeSelector';

import { postEvent        }  from '../../../../../network/api_events_stats';

import { INTERNAL_LINKS   }  from '../../../../../network/urls';

export interface Part01Props {
  popup?:   boolean
}

export const Part01 = ({ popup = false }: Part01Props) => {

   const navigate = useNavigate();

   const { authentication } = useTypedSelector((state) => state.authenticationManagment );

      const [isModalOpen, setModalOpen] = useState(false); //(popup);

   const yes = (): JSX.Element => {

      return (
              <>
               <img src       = "https://lovelightfactory.fr/images/icons/yes.png"
	                className = {classNames(styles.icon)}
			        alt       = "yes" />
              </>
             )
   }

   const no = (): JSX.Element => {

      return (
              <>
               <img src       = "https://lovelightfactory.fr/images/icons/no.png"
	                className = {classNames(styles.icon)}
			        alt       = "no" />
              </>
             )
   }

   const button = ( text: string   ,
                    fct:  ()=>void ) : JSX.Element => {

      return (
              <>
                    <div className={classNames(styles.btn)}>
                       <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                               onClick   = {fct} >
                          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">{text}</span>
                       </button>
                    </div>
              </>
             )
   }

   const handleButtonClick1 = () => {

       const eventMessage: string = "Home-Part01-Button01:\"Je m'inscris gratuitement\""

       postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

       navigate ( `${INTERNAL_LINKS.Registration}`)
   }

   const handleButtonClick2 = () => {

       const eventMessage: string = "Home-Part01-Button02:\"Je m'abonne\""

       postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

       navigate ( `${INTERNAL_LINKS.Subscription}`)
   }

   const handleButtonClick2b = () => {

       const eventMessage: string = "Home-Part01-Button03:\"Je m'abonne\""

       postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

       navigate ( `${INTERNAL_LINKS.Subscription}`)
   }

   const offers:Item[] =
     [
       {
         title:        <>

	                     <div className={classNames(styles.offer_super_title)} >Offre Découverte</div>
	                     <br/>
                         <div className={classNames(styles.price)} >
	                        <div className={classNames(styles.free_block)} >
	                           <div className={classNames(styles.price)} >Gratuit</div>
                            </div>
                         </div>

                       </> ,

         description:  <>

                         <div className={classNames(styles.tab)}>

                            <div className={classNames(styles.tab_1_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_1_2)}>
	                           Vidéo sans coupure pub
                            </div>

                            <div className={classNames(styles.tab_2_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_2_2)}>
	                           Accès aux vidéos avant la sortie sur Youtube
                            </div>

                            <div className={classNames(styles.tab_3_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_3_2)}>
	                           Accès au live et découvrez des programmes exclusifs
                            </div>

                            <div className={classNames(styles.tab_4_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_4_2)}>
	                           Posez vos questions pendant les lives privées
                            </div>

                            <div className={classNames(styles.tab_5_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_5_2)}>
	                           Accès aux offres  <br/>
  	                           &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo; <br/>
		                       <span className={classNames(styles.bold_italic)}>(abonnement annuel premium remboursé à l'achat de la première commande d'une Masterclass Urban Tour)</span>
                            </div>

                            <div className={classNames(styles.tab_6_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_6_2)}>
                               Invitation aux événements Lovelight
                            </div>

                         </div>

                         <br/>

	                     {button ("Je m'inscris gratuitement",handleButtonClick1)}

                       </> ,

         link: "",
       },
       {
         title:        <>

	                     <div className={classNames(styles.offer_super_title)} >Offre Standard</div>
	                     <br/>
	                     <div className={classNames(styles.offers)} >
                            <div className={classNames(styles.annual)} >
	                           <div className={classNames(styles.price)} >5,90 €/mois</div>
	                           <div className={classNames(styles.price)} >Offre annuelle</div>
	                           <span className={classNames(styles.unbold)} >1 paiement de 69 €</span>
                            </div>
                            {/*
                            <div className={classNames(styles.mensual)} >
	                           <div className={classNames(styles.price)} >7,90 €/mois</div>
	                           <div className={classNames(styles.price)} >Offre mensuelle</div>
	                           <span className={classNames(styles.unbold)} >Offre sans engagement</span>
                            </div>
                            */}
	                     </div>

                       </> ,

         description:  <>

                         <div className={classNames(styles.tab)}>

                            <div className={classNames(styles.tab_1_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_1_2)}>
                               Vidéo sans coupure pub
                            </div>

                            <div className={classNames(styles.tab_2_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_2_2)}>
	                           Accès aux vidéos avant la sortie sur Youtube
                            </div>

                            <div className={classNames(styles.tab_3_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_3_2)}>
	                           Accès au live et découvrez des programmes exclusifs
                            </div>

                            <div className={classNames(styles.tab_4_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_4_2)}>
	                           Posez vos questions pendant les lives privées
                            </div>

                            <div className={classNames(styles.tab_5_1)}>
                               {no()}
	                        </div>

                            <div className={classNames(styles.tab_5_2)}>
	                           Accès aux offres  <br/>
  	                           &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo; <br/>
		                       <span className={classNames(styles.bold_italic)}>(abonnement annuel premium remboursé à l'achat de la première commande d'une Masterclass Urban Tour)</span>
                            </div>

                            <div className={classNames(styles.tab_6_1)}>
                               {no()}
	                        </div>

                            <div className={classNames(styles.tab_6_2)}>
	                           Invitation aux événements Lovelight
                            </div>

                         </div>

                         <br/>

	                     {button ("Je m'abonne",handleButtonClick2)}

                       </> ,

         link: "",
       },
       {
         title:        <>

 	                     <div className={classNames(styles.offer_super_title)} >Offre Premium</div>
	                     <br/>
	                     <div className={classNames(styles.offers)} >
  	                        <div className={classNames(styles.annual)} >
	                           <div className={classNames(styles.price)} >9,90 €/mois</div>
	                           <div className={classNames(styles.price)} >Offre annuelle</div>
	                           <span className={classNames(styles.unbold)} >1 paiement de 119 €</span>
                            </div>
                            {/*
                            <div className={classNames(styles.mensual)} >
	                           <div className={classNames(styles.price)} >12,90 €/mois</div>
	                           <div className={classNames(styles.price)} >Offre mensuelle</div>
	                           <span className={classNames(styles.unbold)} >Offre sans engagement</span>
                            </div>
                            */}
	                     </div>

                       </> ,

         description:  <>

                         <div className={classNames(styles.tab)}>

                            <div className={classNames(styles.tab_1_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_1_2)}>
	                           Vidéo sans coupure pub
                            </div>

                            <div className={classNames(styles.tab_2_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_2_2)}>
	                           Accès aux vidéos avant la sortie sur Youtube
                            </div>

                            <div className={classNames(styles.tab_3_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_3_2)}>
	                           Accès au live et découvrez des programmes exclusifs
                            </div>

                            <div className={classNames(styles.tab_4_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_4_2)}>
	                           <div className={classNames(styles.boldText)} >
							      Posez vos questions pendant les lives privées
							   </div>
                            </div>

                            <div className={classNames(styles.tab_5_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_5_2)}>
	                           Accès aux offres  <br/>
  	                           &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo; <br/>
		                       <span className={classNames(styles.bold_italic)}>(abonnement annuel premium remboursé à l'achat de la première commande d'une Masterclass Urban Tour)</span>
                            </div>

                            <div className={classNames(styles.tab_6_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_6_2)}>
	                           <div className={classNames(styles.boldText)} >Invitation aux événements Lovelight</div>
                            </div>

                         </div>

                         <br/>

	                     {button ("Je m'abonne",handleButtonClick2b)}

                       </> ,

         link: "",
       }
   ]

     const handleOpenModal = () => setModalOpen(true);
   const handleCloseModal = () => setModalOpen(false);

   return (

        <div className={classNames(styles.part1)}>

          <div className={classNames(styles.contain)}>

            <div className="w-full rounded-md flex md:justify-center relative overflow-hidden">

              <div className="p-4 max-w-9xl  mx-auto relative z-10  w-full pt-20 md:pt-20">

                <h1 className="text-4xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
                  La plateforme de la Photo et de l'Audiovisuel.
                </h1>

    		    <p className="mt-4 font-normal text-4xl text-neutral-300 max-w-lg text-center mx-auto">
                  Explorez - Apprenez - Créez
                </p>

		        <br/>

                <div className="max-w-7xl mx-auto px-8">
                  <HoverEffect items={offers} />
                </div>

              </div>

            </div>

          </div>


         {/* Bouton en haut à droite
         <div className={classNames(styles.btn, 'absolute top-24 right-4')} style={{ zIndex: 6000 }}>

         <div className={classNames(styles.btn)}>
           <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
		            onClick = {handleOpenModal} >
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-3 py-1 text-xl font-medium text-black backdrop-blur-3xl">
               Aidez la chaîne
             </span>
           </button>
          </div>

         </div>
*/}

         {isModalOpen && (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black/90 p-6"
    style={{ zIndex: 9999 }}
  >
    <div
      className="max-w-[95%] w-[95%] sm:w-[70%] max-h-[95%] bg-black border-4 border-white rounded-lg p-6 relative overflow-y-auto"
      style={{ height: "auto" }}
    >
      {/* Bouton de fermeture */}
      <button
        className="absolute top-4 right-4 text-white text-3xl"
        onClick={handleCloseModal}
      >
        ×
      </button>
      {/* Contenu de la modale */}
      <div className="items-center justify-center h-full text-white">
        <img src = "/images/LovelightTV-70.png"
			 className = {classNames(styles.logo)}
			 alt       = "Logo LovelightTV"/>
        <br />
        <p className="text-3xl font-bold">
          Soutenez Lovelight TV et notre passion commune pour l’image !
        </p>
        <br />
        <p className="text-2xl">Chers amis de Lovelight,</p>
        <br />
        <p className="text-2xl">
          Depuis deux ans, Lovelight s’est imposée comme un média de référence
          dans le monde de la photographie et de l’audiovisuel grâce à la
          qualité et la créativité de ses programmes et la passion qui anime
          l’équipe.
        </p>
        <br />
        <p className="text-2xl">
          Cette année nous avons de nouvelles ambitions en termes de production
          audiovisuelle qui vont nécessiter des investissements importants
          (catalogue de nouveaux programmes, visite des manufactures des marques
          à travers le monde, investissements en matériel audiovisuel). Chaque
          projet est pour nous une occasion de partager avec vous notre amour de
          l’image, mais pour continuer cette belle aventure, nous avons besoin
          de votre aide.
        </p>
        <br />
        <p className="text-2xl">
          Votre soutien est essentiel pour nous permettre de continuer à grandir,
          à innover et à partager avec vous notre passion de la photographie et
          de l’audiovisuel.
        </p>
        <br />
        <p className="text-2xl">
          <span className="text-2xl font-bold">Chaque don, petit ou grand, fait une différence.</span> En
          quelques clics, vous pouvez nous aider à écrire le prochain chapitre de
          cette aventure et contribuer directement à la création de contenus qui
          vous passionnent.
        </p>
        <br />
        <p className="text-2xl">
          👉 <span className="text-2xl font-bold">Faites un don dès maintenant</span> et devenez acteur de cette belle
          histoire :
        </p>
        <div className="flex justify-center items-center mt-4">
          <Link
      to="https://www.paypal.com/donate/?hosted_button_id=HNJ8W4T5BDDTJ"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/images/Paypal_donate.png"
        alt="Bouton Faites un don avec PayPal"
        style={{ height: "40px", cursor: "pointer" }}
      />
    </Link>
        </div>
        <br />
        <p className="text-2xl">
          Nous vous remercions du fond du cœur. Ensemble, faisons de Lovelight une
          lumière qui ne s’éteint jamais.
        </p>
      </div>
    </div>
  </div>
)}


        </div>
   );




};

export default Part01;

