import   React                         from 'react';
import   ReactDOM                      from 'react-dom/client';
import { createBrowserRouter        ,
         RouterProvider             }  from 'react-router-dom';
import { Provider                   }  from 'react-redux';

import { Home                       }  from './components/page/home/home';
import { Login                      }  from './components/page/login/login';
import { AccountManagment           }  from './components/page/account_managment/account_managment';
import { AccountCreation            }  from './components/page/account_creation/account_creation';
import { AccountCreationSuccess     }  from './components/page/account_creation_result/account_creation_success';
import { PaymentFail                }  from './components/page/payment_results/fail';
import { PaymentSuccess             }  from './components/page/payment_results/success';
import { DonationSuccess            }  from './components/page/payment_results/donation_success';
import { Replay                     }  from './components/page/replay/replay';
import { Shop                       }  from './components/page/shop/shop';
import { CartContent                }  from './components/page/cart/cart_content';
//import { Download                   }  from './components/page/download/download';
//import { Club                       }  from './components/page/club/club';
import { Masterclass                }  from './components/page/masterclass/masterclass';
import { PasswordReset              }  from './components/page/connection_informations_reset/password_reset';
//import { EmailReset                 }  from './components/page/connection_informations_reset/email_reset';
import { LegalNotice                }  from './components/page/mandatory_informations/legal_notice';
import { TermsOfService             }  from './components/page/mandatory_informations/terms_of_service';
import { CookiesInformation         }  from './components/page/mandatory_informations/cookies_information';
import { PersonnalDatasInformation  }  from './components/page/mandatory_informations/personnal_datas_information';
import { VideoViewer                }  from './components/page/viewer/video_viewer';
import { BroadcastingViewer         }  from './components/page/viewer/broadcasting_viewer';
import { UnderMaintenance           }  from './components/page/under_maintenance/under_maintenance';
import { NotFound                   }  from './components/page/errors/not_found';
import { UrbanTour_Nice             }  from './components/page/urbantour/nice/nice';
import { UrbanTour_Rome             }  from './components/page/urbantour/rome/rome';
import { UrbanTour_Registration     }  from './components/page/urbantour/registration/urban_tour_registration';

import { useTypedSelector    }  from './datas/useTypeSelector'; 
import { store                      }  from './datas/store'; 
import { isTokenValid        }  from './network/utils'; 

import { INTERNAL_LINKS             }  from './network/urls';

import { checkApplicationUpdate }  from './network/api_front_information'; 

import './index.css';


//checkApplicationUpdate()

const router_normal = createBrowserRouter([

  {
    path:    `${INTERNAL_LINKS.Home}`,
    element: <Home />,
  },
  {
    path:    `${INTERNAL_LINKS.HomeWithPopup}`,
    element: <Home popup={true}/>,
  },
  {
    path:    `${INTERNAL_LINKS.Login}`,
    element: <Login />,
  },
  {
    path:    `${INTERNAL_LINKS.AccountManagment}`,
    element: <AccountManagment />,
  },
  {
    path:    `${INTERNAL_LINKS.Registration}`,
    element: <AccountCreation withOfferChoice = {false}/>,
  },
  {
    path:    `${INTERNAL_LINKS.Subscription}`,
    element: <AccountCreation withOfferChoice = {true}/>,
  },
  {
    path:    `${INTERNAL_LINKS.AccountCreationSuccess}`,
    element: <AccountCreationSuccess />,
  },
  {
    path:    `${INTERNAL_LINKS.PaymentFail}`,
    element: <PaymentFail />,
  },
  {
    path:    `${INTERNAL_LINKS.PaymentSuccess}`,
    element: <PaymentSuccess />,
  },
  {
    path:    `${INTERNAL_LINKS.DonationSuccess}`,
    element: <DonationSuccess />,
  },
  {
    path:    `${INTERNAL_LINKS.Replay}`,
    element: <Replay />,
  },
  {
    path:    `${INTERNAL_LINKS.Shop}`,
    element: <Shop />,
  },
  {
    path:    `${INTERNAL_LINKS.Cart}`,
    element: <CartContent />,
  },
  {
    path:    `${INTERNAL_LINKS.Masterclass}`,
    element: <Masterclass />,
  },
  {
    path:    `${INTERNAL_LINKS.PasswordReset}`,
    element: <PasswordReset />,
  },
  {
    path:    `${INTERNAL_LINKS.LegalNotice}`,
    element: <LegalNotice />,
  },
  {
    path:    `${INTERNAL_LINKS.TermsOfService}`,
    element: <TermsOfService />,
  },
  {
    path:    `${INTERNAL_LINKS.CookiesInformation}`,
    element: <CookiesInformation />,
  },
  {
    path:    `${INTERNAL_LINKS.PersonnalDatasInformation}`,
    element: <PersonnalDatasInformation />,
  },
  {
    path:    `${INTERNAL_LINKS.VideoViewer}`,
    element: <VideoViewer />,
  },
  {
    path:    `${INTERNAL_LINKS.Streaming}`,
    element: <BroadcastingViewer />,
  },
  
  {
    path:    `${INTERNAL_LINKS.UrbanTour_Nice}`,
    element: <UrbanTour_Nice />,
  },
  {
    path:    `${INTERNAL_LINKS.UrbanTour_Rome}`,
    element: <UrbanTour_Rome />,
  },
  {
    path:    `${INTERNAL_LINKS.UrbanTour_Registration}`,
    element: <UrbanTour_Registration />,
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

const router_maintenance = createBrowserRouter([

  {
    path: "*",
    element: <UnderMaintenance />,
  },
  
]);

const root = ReactDOM.createRoot ( document.getElementById('root') as HTMLElement );

const unsubscribe = store.subscribe(() => {});

root.render (

 <React.StrictMode>
   <Provider store={store}>
     <RouterProvider router={router_normal} />
   </Provider>
 </React.StrictMode>
 
);

/*
if (module.hot) {
  module.hot.accept();
}
*/
