import   React              ,
       { useEffect          ,
         useState           }  from 'react';

import { Link               ,
         useLocation        ,
		 useNavigate        }  from 'react-router-dom';

import { Dispatch           }  from 'redux';
import { useDispatch        }  from 'react-redux';

import   classNames            from 'classnames';

import   styles                from './part_02.module.scss';

import { BackgroundGradient }  from "../../../../../ui/background-gradient";

import { Window             }  from '../../../../../widget/window/window';
import { WindowWithField    }  from '../../../../../widget/window/window_with_field';
import { WindowWithForm     }  from '../../../../../widget/window/window_with_form';

import { postInformationRequest }  from '../../../../../../network/api_urban_tour';




import { useTypedSelector   }  from '../../../../../../datas/useTypeSelector';

import { initCart           }  from '../../../../../../datas/cartManagment/actionCreators';
import { initAuthentication }  from '../../../../../../datas/authenticationManagment/actionCreators';
import { initUser           }  from '../../../../../../datas/userManagment/actionCreators';

import { postEvent          }  from '../../../../../../network/api_events_stats';

import { INTERNAL_LINKS     ,
         WWW_URLS           }  from '../../../../../../network/urls';
import { isTokenValid       }  from '../../../../../../network/utils'; 


export interface Part02Props {
  showAlertWindow: () => void;
}

export const Part02 = ({ showAlertWindow }: Part02Props) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate();

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment           );

  const [ alertInformationRequestWindowVisible , setAlertInformationRequestWindowVisible ] = useState ( false );
  const [ alertWindowDeprecatedTokenVisible    , setAlertWindowDeprecatedTokenVisible    ] = useState ( false );

  const currentUrbanTour: string = "Lovelight Urban Tour spécial Rome"

   const renderText4 = (): JSX.Element => {

      return (
               <div className={classNames(styles.block_text)}>
                  <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
                     Retrouvez l’équipe de Lovelight et découvrez la Ville Éternelle
                  </p>
                  <br/>
                  <br/>
		          <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
                     Accordez-vous un séjour exceptionnel à Rome, une ville où le passé et la modernité se rencontrent. Cette masterclass 
					 à travers l’histoire de l’art  en petit groupe (8 à 12 personnes) vous donnera l’occasion de photographier des sites 
					 et des chefs d’œuvres emblématiques tout en perfectionnant vos compétences techniques et artistiques.<br/>
                     <br/>
                     Ce voyage spécialement organisé pour les amateurs ou experts passionnés de photographie vous permettra d’échanger 
					 avec d’autres membres de la communauté Lovelight. Vous pourrez capturer la beauté intemporelle de la capitale 
					 italienne, accompagné de professionels de la photographie reconnus et de l’équipe Lovelight. Vous apprendrez 
					 quotidiennement à travers des ateliers, des photowalks tout en explorant les sites historiques mais aussi des lieux 
					 plus insolites de la ville !<br/>
                     <br/>
                     Une lecture de portfolio de chaque participant sera menée par votre formateur proposant ainsi des conseils et 
					 revenant sur les erreurs à éviter dans l’esprit de l’émission jeu Lovelight Challenge.
		          </p>



               </div>
             )
   };

   const renderThumbnail4 = (): JSX.Element => {

      return <>
    	  <div className={classNames(styles.contain)}>
		  
    	  <div className={classNames(styles.subcontain)}>
        <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
           Prochaine session
        </p>
        <p className="text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
           Du 5 au 9 mars 2025
        </p>

          <div className={classNames(styles.btn)}>

	       <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
		           onClick   = {handleSubscriptionOnUrbanTour}>
             <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">
               Brochures & Inscriptions
             </span>
           </button>
           {/*
		   
		   <button className = "px-6 py-2 rounded-full bg-[#333333] font-bold text-sm text-[#666666] tracking-widest uppercase transform border border-black  cursor-default"
                   onClick   = {handleSubscriptionOnUrbanTour}>
              Je m'inscris
           </button>
		   */}

          </div>
      </div>
		  
		  
      <BackgroundGradient className="rounded-[22px] p-4 sm:p-10 bg-black dark:bg-zinc-900">
	    <div className={classNames(styles.image)}>
        <img
          src={`${WWW_URLS.UrbanTourThumbnail}Rome/Rome.jpg`}
          alt="Urban Tour Rome"
          height="400"
          width="400"
          className="object-contain"
        />
        </div>
        <p className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200">
          Séances de photographie guidées
        </p>
         <p className="text-sm text-justify text-neutral-600 text-white dark:text-neutral-400">
          Explorez et photographiez les monuments emblématiques de Rome, tels que le Colisée, la célèbre Fontaine de Trevi, le 
		  Vatican, le monument Victor Emmanuel II, la place du Capitole, le Castel Sant’Angelo, le Forum et le Panthéon accompagné 
		  d’un photographe professionnel et de l’équipe Lovelight. Ce séjour vous permettra de découvrir également les trésors 
		  cachés de Rome.
        </p>
        <p className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200">
          Ateliers pratiques quotidiens
        </p>
         <p className="text-sm text-justify text-neutral-600 text-white dark:text-neutral-400">
          Les ateliers vous permettront d’améliorer vos compétences photographiques techniques et artistiques. Chaque jour retrouvez 
		  votre expert en séance Masterclass qui abordera les bases de votre appareil et le mode « manuel »  en passant par des 
		  techniques plus avancées comme  la photographie d’architecture, de  paysages urbains, la photo de nuit, la pose longue 
		  et la streetphotography. 
        </p>

      </BackgroundGradient>


    </div>


           </>
   }


  
  const handleInformationRequest = () => {

    const eventMessage: string = "UrbanTour-Nice-Part02-Button01:\"Demander la brochure\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertInformationRequestWindowVisible ( true );
  }

  const handleSubscriptionOnUrbanTour = () => {
/*
    let status : number = -1; // Non connecté

    if ( ( authentication.login !== ""   &&
           authentication.token !== "" ) )
     {
      status = 0;

       if ( user.status  === "Standard" )  { status = 1 }
       if ( user.status  === "Premium"  )  { status = 2 }
     }

    if ( status !== -1 )
          {
           const eventMessage: string = "UrbanTour-Nice-Part02-Button02:\"Je m'inscris\""

           postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

           const dest = encodeURIComponent ("Rome");

           navigate ( `${INTERNAL_LINKS.UrbanTour_Registration}?destination=${dest}` );
          }
     else {
           showAlertWindow ()
          }
*/

           const eventMessage: string = "UrbanTour-Nice-Part02-Button02:\"Je m'inscris\""

           postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

           const dest = encodeURIComponent ("Rome");

           navigate ( `${INTERNAL_LINKS.UrbanTour_Registration}?destination=${dest}` );
  }


  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }
  /*
  const closeAlertInformationRequestWindowWithCancelButton = () => {

    setAlertInformationRequestWindowVisible ( false );
  }

  const closeAlertInformationRequestWindowWithValidButton = ( firstname: string ,
                                                              lastname:  string ,
                                                              age:       number ,
                                                              email:     string ,
                                                              city:      string ) => {

    setAlertInformationRequestWindowVisible ( false );

    postInformationRequest ( firstname, lastname, age, email, city, `${currentUrbanTour}` )
  }
*/
  return (

            <div className={classNames(styles.part2)}>

			 			 
			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }

	          {/*
	    alertInformationRequestWindowVisible &&
	    ( < WindowWithForm  message               = { <><span className = {classNames(styles.bold_text)}>Je souhaite recevoir par mail des information concernant l'offre "{currentUrbanTour}".</span><br/></> }
                            messageType           = "normal"
                            onClickOnCancelButton = {closeAlertInformationRequestWindowWithCancelButton}
                            onClickOnValidButton  = {closeAlertInformationRequestWindowWithValidButton}
                            cancelButtonName      = "NON"
                            validButtonName       = "OUI"
							stylesGridInfo        = {classNames(styles.grid_info)} /> )
	  */}

               <div className = { classNames ( styles.grid ) } >

	              <div className = { classNames ( styles.block_4_1 ) } > { renderText4      () } </div>
                  <div className = { classNames ( styles.block_4_2 ) } > { renderThumbnail4 () } </div>

	           </div>

            </div>

          );
};

export default Part02;



